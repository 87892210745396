import { useState, useEffect, useContext } from 'react';
//components
import { PrimaryButton } from '@components/Buttons';
import { InputLabel, TextAreaField, TextField } from '@components/TextField';
import { Dropdown } from '@components/Dropdown';
import { PhoneInput } from 'react-international-phone';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
//hooks
import { useForm } from 'react-hook-form';
import { CoreAPIs } from 'src/api';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
//styles
import 'react-international-phone/style.css';
import 'react-datepicker/dist/react-datepicker.css';
//constants
import { telephonyOptions } from 'src/constants/call';
import { languageOptions, Languages } from 'src/constants/language';
import { Telephony } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';
import moment from 'moment';
import { useGetAgentList } from 'src/hooks/apis/agents/useGetAgentList';

const modifiedTelephonyOptions = [...telephonyOptions, { name: 'Random', id: 'random' }];

const genderOptions = [
  { name: 'Male', id: 'male' },
  { name: 'Female', id: 'female' },
  { name: 'Other', id: 'other' },
];

const agentOptions = [
  { name: 'Knowl', id: 'knowl' },
  { name: 'Retell', id: 'retell' },
  { name: 'Bland', id: 'bland' },
];

type UserInfo = {
  amount_due: number;
  total_amount: number;
  due_date: Date;
};

type FormData = {
  fullName: string;
  user_info: UserInfo;
  gender: string;
  telephony: Telephony;
  prompt?: string;
  penal_charges: number;
  offer_amount: number;
  offer_expiry: string | null;
  offer_treatment?: string;
  ptp_datetime?: Date;
  ptp: boolean;
  //company_id?: CompanyNode['id'];
};

export default function AgentCallingForm() {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 14);

  const { isAdmin, companyList } = useContext(AuthContext) as AuthContextInterface;
  const [platform, selectPlatform] = useState<string | null>(isAdmin ? null : 'knowl');
  const [agent, selectAgent] = useState<string | null>(null);
  const [telephony, selectTelephony] = useState<string | null>(isAdmin ? null : 'random');
  const [dueDate, setDueDate] = useState<Date>(startDate);
  const [ptpDate, setPtpDate] = useState<Date | null>(null);
  const [offerExpiryDate, setOfferExpiryDateDate] = useState<Date | null>(null);
  const [language, selectLanguage] = useState<string | null>(Languages.Hindi);
  const [gender, selectGender] = useState<string>();
  const [phone, setPhone] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<CompanyNode | null>(null);
  const [shouldShowConfModal, toggleConfModal] = useState<boolean>(false);

  const { data: callingAgents = [] } = useGetAgentList();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      fullName: '',
      gender,
      prompt: '',
      penal_charges: 0,
      offer_expiry: null,
      offer_amount: 0,
      offer_treatment: '',
      ptp: false,
      user_info: {
        amount_due: 400,
        total_amount: 23000,
        due_date: new Date(),
      },
    },
    mode: 'onSubmit',
  });

  const handlePlatformChange = (val: any) => {
    if (val) {
      selectPlatform(val.id);
    }
  };

  const handleTelephonyChange = (val: any) => {
    if (val) {
      selectTelephony(val.id);
    }
  };

  const handleAgentChange = (val: any) => {
    if (val) {
      selectAgent(val.id);
    }
  };

  const handleLanguageChange = (val: any) => {
    if (val) {
      selectLanguage(val.id);
    }
  };
  const handleGenderChange = (val: any) => {
    if (val) {
      selectGender(val.id);
    }
  };

  const handleCompanyChange = (val: any) => {
    if (val) {
      setSelectedCompany(val);
    }
  };

  const onSubmit = async () => {
    const payload = {
      phone_number: phone,
      name: getValues().fullName,
      platform,
      agent,
      language,
      gender,
      prompt: getValues().prompt,
      telephony,
      offer_treatment: getValues().offer_treatment,
      offer_amount: getValues().offer_amount,
      penal_charges: getValues().penal_charges,
      ptp: ptpDate ? true : false,
      ptp_datetime: ptpDate ? moment(ptpDate.toISOString()).format('YYYY-MM-DDTHH:mm:ss') : undefined,
      offer_expiry: offerExpiryDate ? offerExpiryDate.toISOString().split('T')[0] : null,
      user_info: {
        ...getValues().user_info,
        due_date: dueDate.toISOString().split('T')[0],
      },
    };
    if (isAdmin && selectedCompany) {
      payload['company_id'] = selectedCompany?.id;
    }
    await CoreAPIs.makeCall(payload);

    toast.success('Call placed successfully.');
  };

  useEffect(() => {
    selectPlatform('knowl');
    selectAgent('random');
    selectTelephony('random');
  }, []);

  const callingAgentsDropdown = callingAgents.map((agent) => {
    return {
      name: agent,
      id: agent,
    };
  });

  callingAgentsDropdown.push({ name: 'Random', id: 'random' });

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center overflow-y-scroll">
      <div className="flex flex-col w-1/2 gap-y-10">
        <div className="text-4xl">Knowl AI Collection Agent Testing</div>
        <div className="flex flex-col gap-y-4 w-full h-full">
          <form className="w-full flex flex-col gap-y-5 " onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex justify-between items-center gap-x-4">
              <TextField
                label="Name"
                placeholder="John Doe"
                mandatory
                {...register('fullName', {
                  required: 'This field is mandatory',
                  setValueAs: (value: string) => value.trim(),
                })}
              />
              <Dropdown
                mandatory
                placeholder="Select Gender"
                options={genderOptions}
                onSelect={handleGenderChange}
                label="Gender"
              />

              <Dropdown
                placeholder="Select Language"
                options={languageOptions}
                onSelect={handleLanguageChange}
                label="Language"
                defaultValue={'Hindi'}
              />
            </div>
            <div className="flex justify-between items-center gap-x-4 w-full">
              <div className="flex flex-col gap-y-1 w-full">
                <InputLabel label="Phone number" mandatory />
                <PhoneInput
                  defaultCountry="in"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  inputClassName="rounded-xl w-full h-10 min-h-10 focus:ring-0"
                  className="rounded-xl w-full h-10 min-h-10 "
                  countrySelectorStyleProps={{
                    className: 'h-10 min-h-10 rounded-xl w-12',
                  }}
                />
              </div>
              {isAdmin && (
                <div>
                  <Dropdown
                    // mandatory
                    placeholder="Select Company"
                    options={companyList}
                    onSelect={handleCompanyChange}
                    label="Company"
                    defaultValue={selectedCompany?.name}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center gap-x-4 w-full">
              <TextField
                label="Amount Due"
                type="number"
                placeholder="50000"
                mandatory
                {...register('user_info.amount_due', {
                  min: 0,
                  valueAsNumber: true,
                  required: 'This field is mandatory',
                })}
              />
              <TextField
                label="Loan Amount"
                placeholder="50000"
                type="number"
                mandatory
                {...register('user_info.total_amount', {
                  min: 0,
                  valueAsNumber: true,
                  required: 'This field is mandatory',
                })}
              />
              <TextField
                label="Penalty"
                type="number"
                placeholder="50000"
                {...register('penal_charges', {
                  min: 0,
                  valueAsNumber: true,
                  required: 'This field is mandatory',
                })}
              />
              <div className="flex flex-col">
                <InputLabel label="Due Date" mandatory />
                <DatePicker
                  selected={dueDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: Date) => setDueDate(date)}
                  className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                />
              </div>
            </div>
            {/* <div className="flex justify-between items-center gap-x-4 w-full">
                  <TextField
                    label="Offer Amount"
                    type="number"
                    placeholder="50000"
                    // mandatory
                    {...register('offer_amount', {
                      min: 0,
                      valueAsNumber: true,
                      // required: 'This field is mandatory',
                    })}
                  />
                  <div className="flex flex-col">
                    <InputLabel label="Offer Expiry" />
                    <DatePicker
                      selected={offerExpiryDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date) => setOfferExpiryDateDate(date)}
                      className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                    />
                  </div>
                </div> */}
            <div className="flex justify-between items-center gap-x-4 w-full">
              {isAdmin && (
                <div className="w-full">
                  <Dropdown
                    mandatory
                    placeholder="Select Agent"
                    options={callingAgentsDropdown}
                    onSelect={handleAgentChange}
                    label="Agent"
                    defaultValue={'Random'}
                  />
                </div>
              )}
              {isAdmin && (
                <div className="w-full">
                  <Dropdown
                    mandatory
                    placeholder="Select calling Platform"
                    options={agentOptions}
                    onSelect={handlePlatformChange}
                    label="Platform"
                    defaultValue={'Knowl'}
                  />
                </div>
              )}
              {isAdmin && (
                <div className="w-full">
                  <Dropdown
                    mandatory
                    placeholder="Select Telephony"
                    options={modifiedTelephonyOptions}
                    onSelect={handleTelephonyChange}
                    label="Telephony Partner"
                    defaultValue="Random"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center gap-x-4 w-full">
              <TextField
                label="Offer treatment"
                type="string"
                placeholder="Add offer treatment..."
                {...register('offer_treatment', {
                  //required: 'This field is mandatory',
                })}
              />
              <div className="flex flex-col">
                <InputLabel label="PTP" />
                <DatePicker
                  placeholderText="Select PTP"
                  showTimeSelect
                  selected={ptpDate}
                  // minDate={new Date()} // Prevent selecting dates in the past
                  // minTime={new Date().getHours() === new Date().getHours() ? today : undefined} // Adjust for the current time
                  // maxTime={new Date(new Date().setHours(23, 59, 59))} // Maximum selectable time for the day
                  dateFormat="dd/MM/yyyy h:mm aa"
                  onChange={(date: Date) => setPtpDate(date)}
                  className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                />
              </div>
            </div>
            {isAdmin && (
              <div className="w-full">
                <TextAreaField label="Prompt" placeholder="Enter the prompt" {...register('prompt', {})} />
              </div>
            )}

            <div className="w-1/3 mt-4">
              <PrimaryButton
                type="submit"
                disabled={!gender || !telephony || !platform || !agent || !phone || !isValid || isSubmitting}
              >
                Submit
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
