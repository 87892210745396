import { AdvancedFilterKeys } from 'src/container/CallLogSettings/AdvancedFilters';
import { CompanyUser, UserNode, WorkspaceNode } from 'src/typings/user.types';

export const USER_INFO_KEY = 'uuid';
export const GIT_ACCESS_TOKEN_KEY = 'gat';
export const GIT_REFRESH_TOKEN_KEY = 'grt';
export const COMPANY_USERS = 'users';
export const SELECTED_GIT_INFO = 'gi';
export const INVITE_ID = 'ii';
export const DOCUMENT_BRANCH_CREATION_INFO = 'dbci';
export const GIT_REPOS = 'repos';
export const THEME = 'theme';
export const SIDEBAR_WIDTH = 'sw';

export const getDataFromStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {}
};

export const removeStorageData = (key: string) => {
  localStorage.removeItem(key);
};

export const setDataInStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getUserInfo = () => {
  return getDataFromStorage(USER_INFO_KEY);
};

export const storeUserInfo = (info: UserNode | null) => {
  setDataInStorage(USER_INFO_KEY, info);
};

export const clearUserInfo = () => {
  const infoKeys = [USER_INFO_KEY, INVITE_ID, GIT_ACCESS_TOKEN_KEY, GIT_REPOS, COMPANY_USERS];
  infoKeys.forEach((key) => {
    removeStorageData(key);
  });
};

export const setGitTokens = (accessToken: string, refreshToken: string | null) => {
  setDataInStorage(GIT_ACCESS_TOKEN_KEY, accessToken);
  refreshToken && setDataInStorage(GIT_REFRESH_TOKEN_KEY, refreshToken);
};

export const getGitTokens = () => {
  const accessToken = getDataFromStorage(GIT_ACCESS_TOKEN_KEY);
  const refreshToken = getDataFromStorage(GIT_REFRESH_TOKEN_KEY);
  return { accessToken, refreshToken };
};

export const removeGitAccessToken = () => {
  removeStorageData(GIT_ACCESS_TOKEN_KEY);
};

export const removeGitRefreshToken = () => {
  removeStorageData(GIT_REFRESH_TOKEN_KEY);
};

export const setInviteId = (value: string) => {
  setDataInStorage(INVITE_ID, value);
};

export const getInviteId = () => {
  return getDataFromStorage(INVITE_ID);
};

export const removeInviteId = () => {
  removeStorageData(INVITE_ID);
};
export const setCompanyUsers = (users: CompanyUser[]) => {
  setDataInStorage(COMPANY_USERS, users);
};

export const getCompanyUsers = () => {
  return getDataFromStorage(COMPANY_USERS) || [];
};

export const isCompanyUsersFetched = () => {
  return getDataFromStorage(COMPANY_USERS) ? true : false;
};

// export const setTheme = (theme: APP_THEME) => {
//   setDataInStorage(THEME, theme);
// };

export const getTheme = () => {
  const val = getDataFromStorage(THEME) || null;
  return val;
};

export const getWorkspaceInfoFromUser = () => {
  const userInfo = getUserInfo();
  return userInfo?.workspace;
};

export const setWorkspaceInfo = (info: WorkspaceNode) => {
  const userInfo = getUserInfo();
  userInfo.workspace = { ...info };
  storeUserInfo(userInfo);
};

export const getAdvancedFiltersCount = () => {
  const prevParams = getDataFromStorage('params');
  let count = 0;
  Object.keys(prevParams).forEach((key) => {
    if (AdvancedFilterKeys.includes(key) && prevParams[key]) {
      count++;
    }
  });
  return count;
};

export const getPrevAdvancedFilters = () => {
  const prevParams = getDataFromStorage('params');
  let filters = {};
  Object.keys(prevParams).forEach((key) => {
    if (AdvancedFilterKeys.includes(key) && prevParams[key]) {
      filters = { ...filters, [key]: prevParams[key] };
    }
  });
  return filters;
};
