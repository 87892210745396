import { CenterModalWithHeader } from '@components/Modal/CenterModalWithHeader';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import AdvancedFilters, { AdvancedCallFilters } from './AdvancedFilters';
import ColumnSelector from './ColumnSelector';
import { ColumnNode } from 'src/constants/call';

type Props = {
  showPopup: boolean;
  toggleShowPopup: (v: boolean) => void;
  onFilterChange: (filters: any) => void;
  isAdmin: boolean;
  onColumnsChange: (c: ColumnNode[]) => void;
  prevFilter: AdvancedCallFilters;
};

const tabs = [
  { id: 0, name: 'Filters', isAllowed: true },
  { id: 1, name: 'Columns', isAllowed: true },
];

export default function CallLogSettings({
  isAdmin,
  showPopup,
  onColumnsChange,
  onFilterChange,
  prevFilter,
  toggleShowPopup,
}: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const tabNodeRefArr = useRef<HTMLDivElement[]>([]);

  const onApplyFilterChanges = () => {
    toggleShowPopup(false);
  };

  const onApplyColumnChanges = (v: ColumnNode[]) => {
    onColumnsChange(v);
    toggleShowPopup(false);
  };

  const left =
    tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().x -
      tabNodeRefArr.current[0]?.getBoundingClientRect().x || 0;

  const width = tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().width;

  return (
    <CenterModalWithHeader
      title="Settings"
      shouldClose
      isVisible={showPopup}
      className="w-[650px] max-h-[500px]"
      onClose={() => toggleShowPopup(false)}
    >
      <div className="flex flex-col justify-start items-start gap-y-2 p-4 w-full min-h-[470px] max-h-[600px]">
        <div className="flex justify-start items-center gap-x-2 relative h-full w-full">
          {tabs?.map((tab, index: number) => {
            const isSelected = selectedIndex === tab.id; //selectedResponseCode?.responseCode === res.responseCode;
            return (
              <div
                key={`apiResponse-${index}`}
                className={twMerge(`cursor-pointer p-2 font-medium`, isSelected ? 'py-2' : '')}
                ref={(element: HTMLDivElement) => (tabNodeRefArr.current[tab.id] = element)}
                onClick={() => {
                  setSelectedIndex(tab.id);
                }}
              >
                {tab.name}
              </div>
            );
          })}
          <div
            className="h-1 bg-[#6f9feb] absolute -bottom-1 left-0 rounded-lg transition-[left] ease-in-out duration-200"
            style={{ left, width: width || '70px' }}
          ></div>
        </div>

        {selectedIndex === 0 && (
          <AdvancedFilters onFilterChange={onFilterChange} prevFilter={prevFilter} onApply={onApplyFilterChanges} />
        )}
        {selectedIndex === 1 && (
          <ColumnSelector isAdmin={isAdmin} onApply={onApplyColumnChanges} onCancel={() => toggleShowPopup(false)} />
        )}
      </div>
    </CenterModalWithHeader>
  );
}
