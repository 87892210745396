import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { Checkbox } from '@mui/material';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
import { useState } from 'react';
import { ColumnNode, columnOptions } from 'src/constants/call';

type Props = {
  isAdmin: boolean;
  onApply: (c: ColumnNode[]) => void;
  onCancel: () => void;
};

export default function ColumnSelector({ isAdmin, onApply, onCancel }: Props) {
  const prevColumns = getDataFromStorage('columns');
  const [selectedFilters, setSelectedFilters] = useState<ColumnNode[]>(prevColumns || []);

  const allowedColumns = columnOptions.filter((i) => (!isAdmin ? !i.isAdminOnly : true));

  const handleChange = (currentCol: ColumnNode) => {
    const index = selectedFilters?.findIndex((l) => l.key === currentCol.key);
    let updatedFilters = [];
    if (index === -1) {
      updatedFilters = [...selectedFilters, currentCol];
      setSelectedFilters([...updatedFilters]);
    } else {
      updatedFilters = selectedFilters.filter((i) => i.key !== currentCol.key);
      setSelectedFilters([...updatedFilters]);
    }
  };

  const handleColumChange = () => {
    setDataInStorage('columns', selectedFilters);
    onApply(selectedFilters);
  };

  return (
    <div className="w-full flex flex-col justify-between h-full gap-y-3 mt-4">
      <div className="flex flex-col gap-1 overflow-y-auto w-full h-full">
        {allowedColumns.map((column) => {
          const isSelected = selectedFilters.findIndex((i) => i.key === column.key) !== -1;

          return (
            <div className="flex justify-start items-center gap-x-1" onClick={() => handleChange(column)}>
              <Checkbox checked={isSelected} />
              <div className="text-sm">{column.name}</div>
            </div>
          );
        })}
      </div>

      <div className="w-full flex justify-end items-center mt-4 gap-x-2">
        <SecondaryButton propClass="w-24" onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton propClass="w-10" onClick={handleColumChange}>
          Apply
        </PrimaryButton>
      </div>
    </div>
  );
}
