import { useState } from 'react';
import { TextField } from '@components/TextField';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { LLMModels } from 'src/constants/llmModels';
import { Dropdown } from '@components/Dropdown';
import { useGetAgentList } from 'src/hooks/apis/agents/useGetAgentList';

export const AdvancedFilterKeys = ['llm_names', 'analytics_tags', 'agent', 'campaign_type'];

export type AdvancedCallFilters = {
  llm_names: string | null;
  analytics_tags: string | null;
  agent: string | null;
  campaign_type: string | null;
};

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: AdvancedCallFilters;
  onApply: () => void;
};

export default function AdvancedFilters({ onFilterChange, prevFilter, onApply }: Props) {
  const [llmModel, setLLMModel] = useState<string>(prevFilter.llm_names || '');
  const [analyticsTag, setAnalyticsTag] = useState<string>(prevFilter.analytics_tags || '');
  const [agent, selectAgent] = useState<string | null>(prevFilter.agent || null);
  const [campaignType, setCampaignType] = useState<string>(prevFilter.campaign_type || '');

  const { data: callingAgents = [] } = useGetAgentList();

  const handleLLMModelChange = (val: any) => {
    if (val) {
      setLLMModel(val.id);
    }
  };

  const handleAnalyticsTagChange = (val: string) => {
    setAnalyticsTag(val);
  };

  const handleCampaignTypeChange = (val: string) => {
    setCampaignType(val);
  };

  const onApplyFilter = () => {
    onFilterChange({
      llm_names: llmModel,
      analytics_tags: analyticsTag,
      agent: agent,
      campaign_type: campaignType,
    });
    onApply();
  };

  const onClearAllFilters = () => {
    setLLMModel('');
    setAnalyticsTag('');
    selectAgent(null);
    onFilterChange({
      llm_names: null,
      analytics_tags: null,
      agent: null,
    });
    onApply();
  };

  const handleAgentChange = (val: any) => {
    if (val) {
      selectAgent(val.id);
    }
  };

  const callingAgentsDropdown = callingAgents?.sort().map((agent) => {
    return {
      name: agent,
      id: agent,
    };
  });

  return (
    <div className="w-full flex flex-col justify-between gap-y-3 mt-4 h-[290px]">
      <div className="w-full">
        <Dropdown
          placeholder="Select LLM Model"
          options={LLMModels}
          onSelect={handleLLMModelChange}
          label="LLM Model"
          defaultValue={prevFilter.llm_names || ''}
        />
      </div>

      <div className="w-full">
        <TextField
          label="Analytics Tags"
          value={analyticsTag}
          placeholder="Enter tags..."
          onChange={(e) => handleAnalyticsTagChange(e.target.value)}
        />
      </div>

      <div className="w-full">
        <Dropdown
          placeholder="Select Agent"
          options={callingAgentsDropdown}
          onSelect={handleAgentChange}
          label="Agent"
          defaultValue={prevFilter.agent || ''}
        />
      </div>

      <div className="w-full">
        <TextField
          label="Campaign Type"
          value={campaignType}
          placeholder="Enter campaign type..."
          onChange={(e) => handleCampaignTypeChange(e.target.value)}
        />
      </div>

      <div className="w-full flex justify-end items-center mt-4 gap-x-2">
        <SecondaryButton propClass="w-24" onClick={onClearAllFilters}>
          Clear all
        </SecondaryButton>
        <PrimaryButton propClass="w-10" onClick={onApplyFilter}>
          Apply
        </PrimaryButton>
      </div>
    </div>
  );
}
