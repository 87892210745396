import ms from 'ms';
import { useQuery } from 'react-query';
import { agents } from 'src/queryClient/queryKeys';
import { CoreAPIs } from '../../../api';

const getAgentList = async (): Promise<string[]> => {
  const data = await CoreAPIs.getCallingAgents();
  return data || [];
};

export const useGetAgentList = () => {
  const result = useQuery<string[], Error>([agents], () => getAgentList(), {
    staleTime: ms('120min'),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: ms('720min'),
  });
  return result;
};
