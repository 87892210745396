export const callLogs = 'callLogs';
export const companies = 'companies';
export const phoneNumbers = 'phoneNumbers';
export const borrowerStats = 'borrowerStats';
export const pickStats = 'pickStats';
export const callStats = 'callStats';
export const paidStats = 'paidStats';
export const borrowerCallHistory = 'borrowerCallHistory';
export const googleDriveFolder = 'googleDriveFolder';
export const agents = 'agents';
