import { Telephony } from 'src/typings/call.types';

export const telephonyOptions = [
  { name: 'Plivo', id: Telephony.Plivo },
  { name: 'Exotel', id: Telephony.Exotel },
];

export type ColumnNode = { name: string; key: string; isAdminOnly: boolean; showByDefault: boolean };

export const columnOptions: ColumnNode[] = [
  // {
  //   name: 'Borrower Name',
  //   key: 'borrower_name',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  // {
  //   name: 'Call To',
  //   key: 'call_to',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  // {
  //   name: 'Duration',
  //   key: 'duration',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  // {
  //   name: 'User Intent',
  //   key: 'intent',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  // {
  //   name: 'DPD',
  //   key: 'dpd',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  // {
  //   name: 'Language',
  //   key: 'borrower_language',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  {
    name: 'Offers',
    key: 'offer_treatment',
    isAdminOnly: false,
    showByDefault: true,
  },
  {
    name: 'Manual Intent',
    key: 'manual_intent',
    isAdminOnly: true,
    showByDefault: false,
  },
  // {
  //   name: 'Audited By',
  //   key: 'audited_by',
  //   isAdminOnly: true,
  //   showByDefault: false,
  // },
  // {
  //   name: 'Call Started at',
  //   key: 'call_start',
  //   isAdminOnly: false,
  //   showByDefault: true,
  // },
  {
    name: 'LLM Models',
    key: 'llm_names',
    isAdminOnly: true,
    showByDefault: false,
  },
  {
    name: 'Agents',
    key: 'agent',
    isAdminOnly: true,
    showByDefault: false,
  },
  {
    name: 'Analytics tags',
    key: 'analytics_tags',
    isAdminOnly: true,
    showByDefault: false,
  },
  {
    name: 'Campaign Type',
    key: 'campaign_type',
    isAdminOnly: false,
    showByDefault: false,
  },
];
