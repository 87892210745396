export enum Languages {
  English = 'en-IN',
  Hindi = 'hi-IN',
  Indonesian = 'id-ID',
  Tamil = 'ta-IN',
  Kannada = 'kn-IN',
  Telugu = 'te-IN',
  Gujarati = 'gu-IN',
  Marathi = 'mr-IN',
  Bengali = 'bn-IN',
}

export const languageOptions = [
  { name: 'Hindi', id: Languages.Hindi },
  { name: 'English', id: Languages.English },
  { name: 'Indonesian', id: Languages.Indonesian },
  { name: 'Tamil', id: Languages.Tamil },
  { name: 'Marathi', id: Languages.Marathi },
  { name: 'Kannada', id: Languages.Kannada },
  { name: 'Telugu', id: Languages.Telugu },
  { name: 'Gujarati', id: Languages.Gujarati },
  { name: 'Bengali', id: Languages.Bengali },
];
