import { CompanyUser } from 'src/typings/user.types';

export const AdminUsers: CompanyUser[] = [
  {
    id: '1',
    first_name: 'Ashvin',
    last_name: 'Sharma',
    is_active: true,
  },
  {
    id: '2',
    first_name: 'Anuj',
    last_name: 'Agarwal',
    is_active: true,
  },
  {
    id: '3',
    first_name: 'Ayush',
    last_name: 'Singh',
    is_active: true,
  },
  {
    id: '4',
    first_name: 'Amit',
    last_name: 'Singhi',
    is_active: true,
  },
  {
    id: '5',
    first_name: 'Anshika',
    last_name: 'Sharma',
    is_active: true,
  },
  {
    id: '6',
    first_name: 'Roopa',
    last_name: 'Gowtham',
    is_active: true,
  },
  {
    id: '7',
    first_name: 'Ananya',
    last_name: 'Ranganath',
    is_active: true,
  },
  {
    id: '8',
    first_name: 'Shonalee',
    last_name: 'Basu',
    is_active: true,
  },
  {
    id: '9',
    first_name: 'Pranshu',
    last_name: 'Agarwal',
    is_active: true,
  },
  {
    id: '10',
    first_name: 'Sarwar',
    last_name: '',
    is_active: true,
  },
  {
    id: '11',
    first_name: 'Jatin',
    last_name: '',
    is_active: true,
  },
  {
    id: '12',
    first_name: 'Saksham',
    last_name: 'Agarwal',
    is_active: true,
  },
  {
    id: '13',
    first_name: 'Gaurav',
    last_name: '',
    is_active: true,
  },
  {
    id: '14',
    first_name: 'Priya',
    last_name: 'Egammai',
    is_active: true,
  },
];
